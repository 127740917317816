<template lang="pug">
  v-container.col-md-12.col-12(style="padding: 0px;")
    odontograma(:patientSelected="patientSelected" :idPatient="idPatient")
    v-dialog(v-model='unsavedChangesDialog' max-width='350')
      v-card
        v-card-title.headline Confirmar si desea cambiar de página
        v-card-text
          | Aún no se ha guardado la cita actual, si continúa no se guardarán los cambios realizados.
        v-card-actions
          v-spacer
          v-btn(color='blue darken-1' text='' @click='closeDialog')
            | Cancelar
          v-btn(color='red darken-1' @click='discardChanges')
            | Continuar
</template>
<script>
  import {enviarEvento} from "@/functions";

  export default {
    // se controla la salida de Odontograma
    beforeRouteLeave (to, from, next) {
      const showConfirmDialog = localStorage.getItem('odontogramFlag')
      if (showConfirmDialog === 'true') {
        if (this.to) {
          next()
          this.unsavedChangesDialog = false
          localStorage.setItem('odontogramFlag', false)
        } else {
          this.to = to
          this.unsavedChangesDialog = true
        }
      } else {
        next()
      }
    },
    components: {
      Odontograma: () => import('@/views/dashboard/pages/Odontograma'),
    },
    props: {
      patientSelected: {
        default: null,
        type: Object,
      },
      idPatient: {
        default: null,
        type: String,
      },
    },
    data: () => ({
      unsavedChangesDialog: false,
      to: null,
    }),
    methods: {
      closeDialog () {
        this.unsavedChangesDialog = false
        this.to = null
      },

      discardChanges () {
        this.unsavedChangesDialog = false
        this.$router.push(this.to)
      },
    },
    mounted() {
      enviarEvento('appointments', { categoria: 'interaccion', etiqueta: 'inicio' })
    }
  }
</script>
